import React from "react"
import ReactSelect from "react-select"
import { getLabel } from "../../utils"
import { FormLabel } from "./formInput"

export const CustomSelect = ({ name, options = [], onChange, ...props }) => (
  <ReactSelect
    {...props}
    name={name}
    options={options}
    onChange={onChange}
    styles={{
      control: (base, props) => ({
        ...base,
        minHeight: "3rem",
        borderRadius: "8px",
        fontSize: "1.2rem",
        borderColor: "#BFC7D2",
        boxShadow: props.isFocused ? `0 0 0 1px #3BBA9C` : "none",
        "&:hover": {
          borderColor: "#3BBA9C",
        },
      }),
      option: (base, props) => ({
        ...base,
        backgroundColor: props.isSelected ? "#3BBA9C" : base.backgroundColor,
      }),
    }}
  />
)
export const CustomSelectProperty = ({
  name,
  options = [],
  onChange,
  ...props
}) => (
  <ReactSelect
    {...props}
    name={name}
    options={options}
    onChange={onChange}
    styles={{
      control: (base, props) => ({
        ...base,
        minHeight: "48px",
        borderRadius: "8px",
        fontSize: "1.2rem",
        borderColor: "#BFC7D2",
        boxShadow: props.isFocused ? `0 0 0 1px #3BBA9C` : "none",
        "&:hover": {
          borderColor: "#3BBA9C",
        },
      }),
      option: (base, props) => ({
        ...base,
        backgroundColor: props.isSelected ? "#3BBA9C" : base.backgroundColor,
      }),
    }}
  />
)

const FormSelect = ({
  label,
  name,
  outerClassName,
  options = [],
  onChange,
  error,
  addProperty = false,
  ...props
}) => {
  return (
    <div className={`${addProperty ? null : "my-4"} ${outerClassName ?? ""}`}>
      <FormLabel>{label ?? getLabel(name)}</FormLabel>
      {addProperty ? (
        <CustomSelectProperty
          {...props}
          name={name}
          options={options}
          onChange={onChange}
        />
      ) : (
        <CustomSelect
          {...props}
          name={name}
          options={options}
          onChange={onChange}
        />
      )}
      {error && <span className="error mx-2">{error}</span>}
    </div>
  )
}

export default FormSelect
