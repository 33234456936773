import React from "react"

const FormToggleButton = ({ active, onClick, children, ...props }) => {
  return (
    <button
      style={{ width: "192px", height: "150px" }}
      type="button"
      {...props}
      className={`form-toggle-btn btn-shadow ${props.className ?? ""} ${
        active ? "active" : ""
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default FormToggleButton
