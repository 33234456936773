import React, { useContext, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import FormToggleButton from "../../formToggleButton"
import ListPropertyContext from "../propertyContext"
import FieldDesc from "../fieldDesc"

export const listedByOptions = [
  {
    value: "owner",
    label: <FormattedMessage defaultMessage="Owner" />,
  },
  { value: "broker", label: <FormattedMessage defaultMessage="Broker" /> },
]

const SecondStep = () => {
  const { values, errors, touched, setFieldValue, setCurrentStepInputs } =
    useContext(ListPropertyContext)

  const handleRelationshipChange = (rel = "owner") => {
    setFieldValue("relationship", rel)
  }

  useEffect(() => {
    setCurrentStepInputs(["relationship"])
  }, [])

  return (
    <div className="res-w-half h-9/10">
      <FieldDesc>
        <FormattedMessage defaultMessage="I am" />
      </FieldDesc>

      <div className="d-flex" style={{ gap: "30px", justifyContent: "center" }}>
        {listedByOptions.map(({ value, label }) => (
          <FormToggleButton
            key={value}
            active={values.relationship === value}
            onClick={() => handleRelationshipChange(value)}
          >
            {label}
          </FormToggleButton>
        ))}
      </div>
      {touched.relationship && errors.relationship && (
        <span className="error mx-2">{errors.relationship}</span>
      )}
    </div>
  )
}

export default SecondStep
