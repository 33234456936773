import React from "react"
import { useIntl } from "react-intl"
import TextArea from "react-textarea-autosize"
import { getLabel } from "../../utils"

export const FormLabel = ({ children }) => (
  <label className="px16 capialised darkGrey">{children}</label>
)

const FormInput = ({
  tag = "input",
  type = "text",
  label,
  error,
  className,
  outerClassName = "",
  labelClassname,
  containerClassName,
  ...props
}) => {
  return (
    <div className={`my-4 ${outerClassName}`}>
      <FormLabel>{label ?? getLabel(props.name)}</FormLabel>
      <div
        className={`signInInput greyLightBg br8 d-flex align-items-center ${
          containerClassName ?? ""
        }`}
      >
        {tag === "textarea" && type === "text" ? (
          <TextArea
            {...props}
            className={`greyLightBg br8 flex-grow-1 px18 ${className ?? ""}${
              labelClassname ?? ""
            }`}
            minRows={2}
          />
        ) : (
          <input
            {...props}
            type={type}
            className={`greyLightBg br8 pe-2 flex-grow-1 px18 ${
              className ?? ""
            }${labelClassname ?? ""}`}
          />
        )}
      </div>
      {error && <span className="error mx-2">{error}</span>}
    </div>
  )
}

export default FormInput
