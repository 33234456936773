import React from "react"

const ChevRight = ({ style = {} }) => {
  return (
    <svg
      width="9"
      height="15"
      viewBox="0 0 12 20"
      fill="none"
      className="localized"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M2 18L10 10L2 2"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ChevRight
