import React, { useContext, useEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import FormToggleButton from "../../formToggleButton"
import ListPropertyContext from "../propertyContext"
import FieldDesc from "../fieldDesc"
import FormInput from "../formInput"

export const offerTypeOptions = [
  { label: <FormattedMessage defaultMessage="For Sale" />, value: "sale" },
  { label: <FormattedMessage defaultMessage="For Rent" />, value: "rent" },
]

const FirstStep = () => {
  const intl = useIntl()
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    setCurrentStepInputs,
  } = useContext(ListPropertyContext)

  const handleOfferTypeChange = (offerType = "rent") => {
    setFieldValue("offerType", offerType)
  }

  useEffect(() => {
    setCurrentStepInputs(["title", "offerType"])
  }, [setCurrentStepInputs])

  return (
    <div className="res-w-half h-9/10 propertyboxfirst">
      <FieldDesc>
        <FormattedMessage defaultMessage="Enter ad title" />
      </FieldDesc>

      <FormInput
        label={<FormattedMessage defaultMessage="Write a title for the ad" />}
        tag="textarea"
        name="title"
        placeholder={intl.formatMessage({
          defaultMessage:
            "GET THE HIGHEST RETURN OF INVESTMENT | POOL VIEW STUDIO ( RENTED 36,500 )",
        })}
        value={values.title}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.title && errors.title}
      />

      <FieldDesc>
        <FormattedMessage defaultMessage="Property is" />
      </FieldDesc>

      <div className="d-flex" style={{ gap: '30px', justifyContent: 'center' }}>
        {offerTypeOptions.map(({ label, value }) => (
          <FormToggleButton
          className="m-2 m-lg-3"
            key={value}
            active={values.offerType === value}
            onClick={() => handleOfferTypeChange(value)}
          >
            {label}
          </FormToggleButton>
        ))}
      </div>
      {touched.offerType && errors.offerType && (
        <span className="error mx-2">{errors.offerType}</span>
      )}
    </div>
  )
}

export default FirstStep
